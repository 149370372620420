import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  filter,
  firstValueFrom,
  map,
  Observable,
  Subscription
} from 'rxjs';
import { DOCUMENT } from '../../../../ecomm/providers/document/document.provider';
import {
  ILegacySeoService,
  LEGACY_SEO_SERVICE,
  structuredDataBreadcrumbList,
  structuredDataCorporation
} from '../../../../ecomm/providers/legacy-providers/seo.service';

import {
  MenuCategory,
  MenuItem,
  StoreInfo
} from '../../../../ecomm/types/store-info.types';
import { FeatureFlagService } from '../../../../ecomm/utils/feature-flag/feature-flag.service';
import { RedirectService } from '../../../../ecomm/utils/redirect/redirect.service';
import { StoreInfoWorkflowService } from '../../../../ecomm/workflows/store-info/store-info-workflow.service';
import { ActiveOfferComponent, MenuItemWithSlug, PartialOutageModalComponent, LoyaltyModalComponent } from '../../../common';
import {
  CartFeature,
  CartFeatureState
} from '../../../../ecomm/store/features/cart';
import { Store } from '@ngrx/store';
import { Cart } from '../../../../ecomm/types/cart.types';
import { addSpaceToBodyWithStickyFooter } from '../../../../ecomm/utils/dom-manipulations';
import {
  StoreInfoFeature,
  StoreInfoFeatureState
} from '../../../../ecomm/store/features/store-info';
import { CustomerFeature, CustomerFeatureState } from '../../../../ecomm/store/features/customer';
import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import { RegionalConfigurationFeature, RegionalConfigurationFeatureState } from '../../../../ecomm/store/features/regional-configuration';
import { Customer, LoyaltyBanner } from '../../../../ecomm/types/customer.types';
import { CustomerWorkflowService } from '../../../../ecomm/workflows/customer/customer-workflow.service';
import { AsynchronousDispatcher } from '../../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import { FeaturesState } from '../../../../ecomm/store/types/features-state';
import { LoyaltyPromptFeature } from '../../../../ecomm/store/features/loyalty-prompt';
import { StoreSnapshotService } from '../../../../ecomm/utils/store-snapshot/store-snapshot.service';

@Component({
  selector: 'wri-connected-home-page',
  styleUrls: ['./connected-home-page.component.scss'],
  template: `
    <div [ngClass]="{ 'wri-homepage': userName }">
      <wri-page-loading-indicator
        *ngIf="isLoading"
      ></wri-page-loading-indicator>

      <ng-container *ngIf="
          loyalty?.eligible && loyalty?.optOutDateTime === null && userName
          then displayBanner;
          else displayReorder
        "></ng-container>

      <ng-template #displayBanner>
        
        <div 
          class="loyalty-banner" 
          [ngClass]="{'without-store-loyalty-banner': storeInfoState === null }"
        >
          <div class="part-1">
            <img src="/assets/wri-loyalty-wing-points.svg">
            <div class="texts">
              <div class="earn-your-wings">
                {{ loyalty?.enrolled ? "0 POINTS" : "Earn your wings" }}
              </div>
              <div class="learn-more">
                <a
                  *ngIf="!loyalty?.enrolled"
                  wriFocusOutline
                  rel="noopener noreferrer"
                  tabindex="0"
                  id="learn-more"
                  href="https://www.wingstop.com/"
                >
                  Learn more
                </a>
              </div>
            </div>
          </div>
          <div class="part-2">
            <a
              wriFocusOutline
              class="earn-or-join"
              rel="noopener noreferrer"
              tabindex="0"
              (click)="navigateOnBannerClick()"
              id="earn-or-join"
              >
              <div class="text-and-arrow">
                <div>{{loyalty?.enrolled ? "EARN" : "JOIN NOW"}}</div>
                <wri-icon class="icon" icon="wri-arrow-right"></wri-icon>
              </div>
            </a>
          </div>
        </div>
      </ng-template>
      <ng-template #displayReorder>
        <ng-container *ngIf="userName">
          <div
            class="user-banner sticky-header"
            [ngClass]="{ 'wri-homepage-cart-unavailable': !cart }"
          >
            <span data-testid="user-banner-name" class="user-banner-name">{{
              userFirstName ? 'Hi, ' + userFirstName + '!' : 'Hi!'
            }}</span>
            <a
              wriFocusOutline
              tabindex="0"
              (click)="reorderClick()"
              class="user-banner-reorder-btn wri-btn wri-btn-text"
              aria-label="Reorder"
              data-testid="reorder-btn"
            >
              {{ (isReorderEnabledPromise | async) ? 'Reorder' : 'My Orders' }}
            </a>
          </div>
        </ng-container>
      </ng-template>
     
      <wri-home-page-hero></wri-home-page-hero>
      <wri-active-offer [showTag]="false"></wri-active-offer>
      <wri-loyalty-modal
        (continueButtonClick)="onLoyaltyPromptContinueClick($event)"
      ></wri-loyalty-modal>
      <wri-partial-outage-modal></wri-partial-outage-modal>
      <div class="mobile-only sticky-footer">
        <div
          (click)="navigateTo('carryout')"
          class="wri-btn wri-btn-primary-icon"
          aria-label="Carryout"
          data-testid="sticky-carryout-cta"
        >
          <wri-icon
            icon="wri-carryout"
            class="wri-carryout"
            data-testid="carryout-img"
          ></wri-icon>

          Carryout
        </div>
        <div class="vertical-separator"></div>
        <div
          (click)="navigateTo('delivery')"
          class="wri-btn wri-btn-primary-icon"
          aria-label="Delivery"
          data-testid="sticky-delivery-cta"
        >
          <wri-icon icon="wri-delivery" data-testid="delivery-img"></wri-icon>

          Delivery
        </div>
      </div>
      <wri-home-page-product-carousel
        [sliderData]="itemsForMenuAdditionalRecommendation$ | async"
      >
      </wri-home-page-product-carousel>
      <wri-home-page-flavor-section>
        *ngIf="featureFlagService.featureFlags.enableFlavorsPage"
      </wri-home-page-flavor-section>

      <wri-home-page-find-your-menu-section>
      </wri-home-page-find-your-menu-section>

      <wri-home-page-wing-calculator>
      </wri-home-page-wing-calculator>
    </div>
  `
})
export class ConnectedHomePageComponent implements OnInit, AfterViewInit, OnDestroy {
  isLoading = false;
  userFirstName: string | null | undefined = null;
  userName: string | null | undefined = null;
  public storeInfo$ = new BehaviorSubject<
    StoreInfo | { categories: MenuCategory[] } | null
  >({ categories: [] });
  public storeInfoState: StoreInfo | null = null;
  public customerState: Customer | null = null;
  cart: Cart | null = null;
  itemsForMenuAdditionalRecommendation$: Observable<MenuItemWithSlug[]> =
    this.storeInfo$.pipe(
      map((storeInfo) => {
        const retVal: MenuItemWithSlug[] = [];
        storeInfo?.categories.forEach((category) => {
          category.products.forEach((product) => {
            if (
              product.item !== null &&
              product.item?.metadata &&
              product.item?.metadata?.['home-product-carousel'] === 'true'
            ) {
              retVal.push({
                categorySlug: category.slug,
                productSlug: product.slug,
                item: product.item
              });
            }

            if (product.itemGroup !== null) {
              product.itemGroup?.items.forEach((item) => {
                if (
                  (item as MenuItem | undefined)?.metadata &&
                  (item as MenuItem | undefined)?.metadata?.[
                  'home-product-carousel'
                  ] === 'true'
                ) {
                  retVal.push({
                    categorySlug: category.slug,
                    productSlug: product.slug,
                    itemSlug: item.slug,
                    item: item
                  });
                }
              });
            }
          });
        });

        return retVal;
      })
    );
  isReorderEnabledPromise: Promise<boolean> | undefined;
  private subscription = new Subscription();
  public regionalConfigState: RegionalConfigurationFeatureState | null = null;
  public loyalty: LoyaltyBanner


  @ViewChild(ActiveOfferComponent) activeOfferComponent:
    | ActiveOfferComponent
    | undefined;

  @ViewChild(PartialOutageModalComponent) partialOutageModalComponent:
    | PartialOutageModalComponent
    | undefined;

  @ViewChild(LoyaltyModalComponent) loyaltyModal:
    | LoyaltyModalComponent
    | undefined;

  public customerState$ = this.store
    .select(CustomerFeature.selectCustomerState)
    .pipe(filter<CustomerFeatureState>(Boolean));

  constructor(
    private storeInfoWorkflowService: StoreInfoWorkflowService,
    private redirectService: RedirectService,
    public featureFlagService: FeatureFlagService,
    private analyticsService: AnalyticsService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(LEGACY_SEO_SERVICE)
    private seoService: ILegacySeoService,
    @Inject(DOCUMENT) private document: Document,
    private store: Store,
    private userAccountService: CustomerWorkflowService,
    @Inject(AsynchronousDispatcher)
    private asynchronusDispatcher: AsynchronousDispatcher<FeaturesState>,
    @Inject(StoreSnapshotService)
    private storeSnapshotService: StoreSnapshotService<FeaturesState>
  ) { }

  public get storeInfo(): StoreInfo | { categories: MenuCategory[] } | null {
    return this.storeInfo$.value;
  }

  public set storeInfo(val: StoreInfo | { categories: MenuCategory[] } | null) {
    if (val) {
      this.storeInfo$.next(val);
    }
  }

  async ngOnInit() {
    addSpaceToBodyWithStickyFooter('home-page');
    this.subscribeToRegionalConfigState();
    this.seoService.addStructuredData([
      structuredDataCorporation(this.document),
      structuredDataBreadcrumbList([
        { name: 'Home', url: this.document.location.origin }
      ])
    ]);

    this.subscribeToCartState();
    this.subscribeToStoreInfoState();
    this.subscribeToCustomerState();
    await this.isReorderEnabled();
    await this.userAccountService.getUserAccount();
  }

  async ngAfterViewInit() {
    const loyaltyPrompt = await firstValueFrom(this.store.select(LoyaltyPromptFeature.selectLoyaltyPromptState));
    setTimeout(async () => {
      //LoyaltyPrompt
      if (this.userName && this.loyalty?.eligible && !this.loyalty?.enrolled && !this.loyalty?.optOutDateTime && !loyaltyPrompt.isLoyaltyPromptDisplayed) {
        await this.asynchronusDispatcher.dispatch(
          LoyaltyPromptFeature.actions.setState({ isLoyaltyPromptDisplayed: true })
        );
        this.loyaltyModal?.openModal(this.customerState?.customerId);
      }

      //redeemCode
      const redeemCode = this.route.snapshot.queryParamMap.get('redeem');
      if (redeemCode) {
        this.activeOfferComponent?.openOfferModal();
      }
    }, 1000);
  }

  async updateCustomer({ eligible, enrolled, optOutDateTime }) {
    const currentStore = await this.storeSnapshotService.get();
    const currentCustomerState = CustomerFeature.selectCustomerState(currentStore);

    await this.asynchronusDispatcher.dispatch(
      CustomerFeature.actions.setState({
        customer: {
          ...currentCustomerState.customer,
          loyalty: {
            eligible: eligible,
            enrolled: enrolled,
            optOutDateTime: optOutDateTime
          }
        },
        error: currentCustomerState.error
      })
    )
  }

  logLoyaltyEnrollPromptResponse(value: string) {
    return this.analyticsService.logGaEvent({
      event: 'loyalty_enroll_prompt_respond',
      user_id: this.customerState?.customerId,
      enroll_response: value
    });
  }

  async onLoyaltyPromptContinueClick({ optedInLoyalty, optedInMarketing }) {
    const res = await this.userAccountService.loyaltyEnrollment({ emailPreference: optedInMarketing, enrolled: optedInLoyalty });
    if (res) {
      if (optedInLoyalty) {
        this.analyticsService.logGaEvent({
          event: 'loyalty_opt_in',
          user_id: this.customerState?.customerId,
        });
        this.logLoyaltyEnrollPromptResponse('opted_in');
        this.updateCustomer({ eligible: true, enrolled: true, optOutDateTime: null })
      } else {
        this.logLoyaltyEnrollPromptResponse('opted_out');
        this.updateCustomer({ eligible: true, enrolled: false, optOutDateTime: new Date() })
      }
      this.loyaltyModal?.closeModal()
    }
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  navigateTo(handOffMode: string) {
    if (this.cart && this.cart.handoffMode === handOffMode) {
      this.router.navigate(['/menu']);
    } else {
      if (this.featureFlagService.featureFlags.enableOrderRoute) {
        this.router.navigate(['/order'], {
          queryParams: {
            handoffMode: handOffMode
          }
        });
      } else {
        this.redirectService.redirectToLegacy(`order/action/${handOffMode}`);
      }
    }
  }

  reorderClick() {
    this.logAnalytics();
    this.router.navigate([`/order/recent`]).then();
  }

  private logAnalytics() {
    this.analyticsService.logGaEvent({
      event: 'button_click',
      event_params: {
        section_title: 'homepage_user_banner',
        button_label: 'reorder'
      }
    });
  }
  private subscribeToCartState(): void {
    const cartState$ = this.store
      .select(CartFeature.selectCartState)
      .pipe(filter<CartFeatureState>(Boolean));

    this.subscription.add(
      cartState$.subscribe((state) => {
        this.cart = state.cart;
      })
    );
  }

  async navigateBasedOnFeatureFlag(configState: RegionalConfigurationFeatureState) {
    this.isLoading = true;

    //redirect to legacy
    if (
      configState?.regionalConfigurationOptions['feature_enable_home_page']
        ?.value === 'false'
    ) {
      this.redirectService.redirectToLegacy();
    }

    const gms =
      configState?.regionalConfigurationOptions?.['global_menu_slug'];
    if (gms && gms.value) {
      this.storeInfo =
        await this.storeInfoWorkflowService.getStoreInfoBySlugWithoutSavingState(
          gms.value,
          'carryout',
          false
        );
    }
    this.isLoading = false;
  }

  private subscribeToRegionalConfigState(): void {
    const regionalConfigState$ = this.store
      .select(RegionalConfigurationFeature.selectRegionalConfigurationState)
      .pipe(filter<RegionalConfigurationFeatureState>(Boolean));

    this.subscription.add(
      regionalConfigState$.subscribe((state) => {
        this.partialOutageModalComponent?.showModal(state);
        this.regionalConfigState = state
        this.navigateBasedOnFeatureFlag(state)
      })
    );
  }

  private subscribeToStoreInfoState(): void {
    const storeInfoState$ = this.store
      .select(StoreInfoFeature.selectStoreInfoState)
      .pipe(filter<StoreInfoFeatureState>(Boolean));

    this.subscription.add(
      storeInfoState$.subscribe((state) => {
        this.storeInfoState = state.storeInfo;
      })
    );
  }

  private subscribeToCustomerState(): void {
    const customerState$ = this.store
      .select(CustomerFeature.selectCustomerState)
      .pipe(filter(Boolean));

    this.subscription.add(
      customerState$.subscribe((state) => {
        this.customerState = state.customer;
        this.userName = state.customer?.userName;
        this.userFirstName = state.customer?.firstName;
        this.loyalty = state.customer?.loyalty
      })
    );
  }

  async isReorderEnabled() {
    if (this.featureFlagService.featureFlags['enableReorder']) {
      const regionalConfigOptions = await firstValueFrom(
        this.store.select(
          RegionalConfigurationFeature.selectRegionalConfigurationOptions
        )
      );
      if (
        regionalConfigOptions &&
        regionalConfigOptions['feature_enable_reorder']?.value === 'false'
      ) {
        this.isReorderEnabledPromise = Promise.resolve(false);
      } else {
        this.isReorderEnabledPromise = Promise.resolve(true);
      }
    } else {
      this.isReorderEnabledPromise = Promise.resolve(false);
    }
  }

  navigateOnBannerClick() {
    if (this.customerState?.loyalty?.enrolled) {
      // earn
      const storeSlug = this.storeInfoState?.storeDetails?.slug
      this.router.navigateByUrl(
        storeSlug ? `/location/${storeSlug}/menu` : '/order'
      );
    } else {
      //join now
      this.loyaltyModal?.openModal(this.customerState?.customerId);
    }
  }
}
