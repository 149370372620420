import {
  Component,
  TemplateRef,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';

@Component({
  selector: 'wri-loyalty-modal',
  templateUrl: './loyalty-modal.component.html',
  styleUrls: ['./loyalty-modal.component.scss']
})
export class LoyaltyModalComponent implements OnDestroy {
  @ViewChild('commonModal') commonModal!: TemplateRef<HTMLElement>;
  @Output()
  continueButtonClick = new EventEmitter<{ optedInLoyalty: boolean, optedInMarketing: boolean }>(false);

  public optedInLoyalty = true;
  public optedInMarketing = true;

  constructor(
    private modalService: NgbModal,
    private analyticsService: AnalyticsService,
  ) { }

  openModal(customerId: string) {
    this.modalService.open(this.commonModal, {
      windowClass: 'common-modal loyalty-modal',
      centered: true,
      size: 'md'
    });

    this.analyticsService.logGaEvent({
      event: 'loyalty_enroll_prompt',
      page: 'Home',
      user_id: customerId
    });

    if (!this.commonModal) {
      console.error('undefined template');
      return;
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  onContinue() {
    this.continueButtonClick.emit({ optedInLoyalty: this.optedInLoyalty, optedInMarketing: this.optedInMarketing })
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }
}
