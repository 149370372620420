import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wri-home-page-hero',
  templateUrl: './home-page-hero.component.html',
  styleUrls: ['./home-page-hero.component.scss']
})
export class HomePageHeroComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute) { }

  private subscription = new Subscription();

  ngOnInit(): void {
    this.getRouteParams();
    this.loadHomepageBanner();
  }

  private getRouteParams() {
    this.subscription.add(
      this.route.params.subscribe(() => {
        this.loadHomepageBanner();
      })
    );
  }

  private loadHomepageBanner() {
    document.getElementById('wri-homepage-hero')?.classList?.add('hidden');
    document.getElementById('wri-homepage-hero')?.classList?.remove('show');
    setTimeout(() => {
      document.getElementById('wri-homepage-hero')?.classList?.add('show');
      document.getElementById('wri-homepage-hero')?.classList?.remove('hidden');
    }, 2500);
  }

  navigateToMenu() {
    this.router.navigate(['menu']);
  }
}
