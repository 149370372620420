import { Inject, Injectable } from '@angular/core';
import { from, map, Observable, of, switchMap, tap } from 'rxjs';

import { CustomerService } from '../../services/customer/customer.service';
import { Cart } from '../../types/cart.types';
import { CustomerFeature } from '../../store/features/customer';
import { FeaturesState } from '../../store/types/features-state';
import {
  Customer,
  CustomerOrders,
  CustomerPutRequest,
  DeliveryAddressPostRequest,
  LoyaltyEnrollment,
  ReOrderPostRequest,
  SignupCustomerRequest,
} from '../../types/customer.types';
import * as MaybeResponse from '../../types/maybe-response';
import * as Worflow from '../../types/workflow';
import * as Workflow from '../../types/workflow';
import { asyncTap } from '../../utils/async-tap';
import { AsynchronousDispatcher } from '../../utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import { AuthService } from '../../utils/auth/auth.service';
import { NotificationService } from '../../utils/notification/notification.service';
import { CartFeature } from '../../store/features/cart';
import { ReCaptchaService } from '../../utils/recaptcha/recaptcha.service';
import { SignupFormData } from '../../../ui/accounts/components/sign-up-form/sign-up-form.component';
import { StoreSnapshotService } from '../../utils/store-snapshot/store-snapshot.service';

@Injectable()
export class CustomerWorkflowService {
  private saveCustomerState = Worflow.onAny<Customer>(
    tap((res) => {
      this.asynchronusDispatcher.dispatch(
        CustomerFeature.actions.setState({
          customer: res.data,
          error: res.error,
        })
      );
    })
  );

  private saveCartState = Workflow.onAny<Cart>(
    asyncTap((res) => {
      return this.asynchronusDispatcher.dispatch(
        CartFeature.actions.setState({
          cart: res.data,
          error: res.error,
        })
      );
    })
  );

  constructor(
    private customerService: CustomerService,
    private notificationService: NotificationService,
    private authService: AuthService,
    @Inject(AsynchronousDispatcher)
    private asynchronusDispatcher: AsynchronousDispatcher<FeaturesState>,
    private reCaptchaService: ReCaptchaService,
    @Inject(StoreSnapshotService)
    private storeSnapshotService: StoreSnapshotService<FeaturesState>
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private setCustomerLoading = <_ extends unknown[]>() => {
    return from(
      this.asynchronusDispatcher.dispatch(
        CustomerFeature.actions.setIsLoading()
      )
    );
  };

  private reportErrors = <T>() =>
    Workflow.onError<T>(
      tap((res) => {
        console.log('res.error', res.error);
        this.notificationService.showError(res.error);
      })
    );

  private reportErrorsStatus = <T>() =>
    Workflow.onError<T>(
      tap((res) => {
        if (res.status !== 304) this.notificationService.showError(res.error);
      })
    );

  public getUserAccount = Worflow.createWorkflow(
    this.setCustomerLoading,
    () => {
      return from(this.getCustomerState()).pipe(
        switchMap((customerState) => {
          return from(this.authService.getCurrentAuth()).pipe(
            map((auth) => auth.user?.access_token),
            switchMap((accessToken) =>
              accessToken
                ? this.customerService.getCustomer(
                    accessToken,
                    customerState.customer
                  ) ?? of(customerState.customer)
                : of({})
            )
          );
        })
      );
    },
    this.reportErrorsStatus(),
    this.saveCustomerState
  );

  public getOrderHistory = Workflow.createWorkflow(
    undefined,
    (): Observable<MaybeResponse.MaybeResponse<CustomerOrders>> =>
      from(this.authService.getCurrentAuth()).pipe(
        map((auth) => auth.user?.access_token),
        switchMap((accessToken) =>
          accessToken
            ? this.customerService.getOrderHistory(accessToken)
            : of({})
        )
      ),
    this.reportErrors()
  );

  public deleteFavoriteLocation = Workflow.createWorkflow(
    undefined,
    (locationId: string) =>
      this.customerService.deleteFavoriteLocation(locationId),
    this.reportErrors()
  );

  public fetchCustomerOnSuccess = Workflow.onSuccess<boolean>(
    asyncTap(async (res) => {
      const customerState = await this.getCustomerState();
      return Workflow.createWorkflow(
        undefined,
        () =>
          from(this.authService.getCurrentAuth()).pipe(
            map((auth) => res.data && auth.user?.access_token),
            switchMap((accessToken) =>
              accessToken
                ? this.customerService.getCustomer(
                    accessToken,
                    customerState?.customer
                  ) ?? of(customerState.customer)
                : of({})
            )
          ),
        this.reportErrors(),
        this.saveCustomerState
      )();
    })
  );

  public updateCustomer = Worflow.createWorkflow(
    this.setCustomerLoading,
    (request: CustomerPutRequest) =>
      from(this.authService.getCurrentAuth()).pipe(
        map((auth) => auth.user?.access_token),
        switchMap((accessToken) =>
          accessToken
            ? this.customerService.updateCustomer(accessToken, request)
            : of({})
        )
      ),
    this.reportErrors(),
    this.saveCustomerState
  );

  private showDeleteSuccessToast = Workflow.onSuccess<boolean>(
    tap(() => {
      this.notificationService.showSuccess(
        'Location has been removed from favorites'
      );
    })
  );

  private showSetFavoriteSuccessToast = Workflow.onSuccess<boolean>(
    tap(() => {
      this.notificationService.showSuccess(
        'Location has been saved to favorites.'
      );
    })
  );

  public setFavoriteLocation = Workflow.createWorkflow(
    undefined,
    (locationId: string) =>
      from(this.customerService.setFavoriteLocation(locationId)),
    this.reportErrors(),
    this.fetchCustomerOnSuccess,
    this.showSetFavoriteSuccessToast
  );

  public unFavoriteLocation = Workflow.createWorkflow(
    undefined,
    (locationId: string) =>
      this.customerService.deleteFavoriteLocation(locationId),
    this.reportErrors(),
    this.fetchCustomerOnSuccess,
    this.showDeleteSuccessToast
  );

  public saveDeliveryAddress = Workflow.createWorkflow(
    undefined,
    (request: DeliveryAddressPostRequest) =>
      from(this.authService.getCurrentAuth()).pipe(
        map((auth) => auth.user?.access_token),
        switchMap((accessToken) =>
          accessToken
            ? this.customerService.saveDeliveryAddress(accessToken, request)
            : of({})
        )
      )
  );

  public deleteMyAddress = Workflow.createWorkflow(
    undefined,
    (addressId: string) => this.customerService.deleteMyAddress(addressId),
    this.reportErrors()
  );

  public reorder = Workflow.createWorkflow(
    undefined,
    (
      request: ReOrderPostRequest
    ): Observable<MaybeResponse.MaybeResponse<Cart>> =>
      from(this.authService.getCurrentAuth()).pipe(
        map((auth) => auth.user?.access_token),
        switchMap((accessToken) =>
          accessToken
            ? this.customerService.reorder(accessToken, request)
            : of({})
        )
      ),
    this.reportErrors(),
    this.saveCartState
  );

  public signUp = Worflow.createWorkflow(
    undefined,
    (formData: SignupFormData) =>
      from(this.reCaptchaService.execute('signup')).pipe(
        switchMap((recaptchaToken) => {
          const request: SignupCustomerRequest = {
            email: formData.email,
            primaryPhone: formData.phone || null,
            dateOfBirth: formData.birthday || null,
            address: {
              postalCode: null,
            },
            firstName: formData.firstName,
            lastName: formData.lastName,
            password: formData.password,
            source: {
              platform: 'web',
            },
          };
          return this.customerService.signUp(request, recaptchaToken);
        })
      ),
    this.reportErrors()
  );

  public deleteAccount = Workflow.createWorkflow(
    undefined,
    () =>
      from(this.reCaptchaService.execute('delete_customer')).pipe(
        switchMap((recaptchaToken) =>
          this.customerService.deleteAccount(recaptchaToken)
        )
      ),
    this.reportErrors(),
    this.fetchCustomerOnSuccess
  );

  private async getCustomerState() {
    const currentStore = await this.storeSnapshotService.get();
    return CustomerFeature.selectCustomerState(currentStore);
  }

  public loyaltyEnrollment = Workflow.createWorkflow(
    undefined,
    (loyaltyEnrollmentObj: LoyaltyEnrollment) =>
      this.customerService.loyaltyEnrollment(loyaltyEnrollmentObj),
    this.reportErrors()
  );
}
