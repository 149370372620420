import {
	createActionGroup,
	createFeature,
	createReducer,
	emptyProps,
	on,
	props,
} from '@ngrx/store';


export type LoyaltyPrompt = {
	isLoyaltyPromptDisplayed: boolean
}
export type LoyaltyPromptFeatureState = LoyaltyPrompt;

const initialLoyaltyPromptFeatureState: LoyaltyPromptFeatureState = {
	isLoyaltyPromptDisplayed: false
};

const LoyaltyPromptActions = createActionGroup({
	source: 'Loyalty Prompt Feature',
	events: {
		'Set State': props<LoyaltyPrompt>(),
		'Reset To Default': emptyProps()
	}
});

const LoyaltyPromptReducer = createReducer(
	initialLoyaltyPromptFeatureState,
	on(
		LoyaltyPromptActions.setState,
		(state, action): LoyaltyPromptFeatureState => ({
			...state,
			isLoyaltyPromptDisplayed: action.isLoyaltyPromptDisplayed ?? null
		})
	),
	on(
		LoyaltyPromptActions.resetToDefault,
		(state): LoyaltyPromptFeatureState => ({
			...state,
			...initialLoyaltyPromptFeatureState
		})
	)
);

const LoyaltyPromptFeatureKey = 'loyaltyPrompt';

const LoyaltyPromptFeature = createFeature({
	name: LoyaltyPromptFeatureKey,
	reducer: LoyaltyPromptReducer
});

export default {
	...LoyaltyPromptFeature,
	actions: LoyaltyPromptActions,
	initialState: initialLoyaltyPromptFeatureState
};
